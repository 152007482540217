<template>
  <Modal :title="$t('ViewGroupNotice')" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div class="group-notice">
      <div class="fl-group">
        <!--確定成團-->
        <div class="title">{{$t('DetermineGroup')}}</div>
        <p>{{$t('Hello',[appellationName])}}</p>
        <!--您訂購的旅遊產品：已確認成-->
        <p>{{$t('Travel products you ordered: confirmed to be a group')}}</p>
      </div>
      <div class="fl-group">
        <!--旅團資料-->
        <div class="title">{{$t('Travel Group Information')}}</div>
        <!--訂單編號-->
        <div>{{$t('Order number')}}：{{orderInfo.orderNo}}</div>
        <!--產品編號-->
        <div class="m-t">{{$t('ProductNumber')}}：{{orderInfo.productNo}}</div>
        <!--產品名稱-->
        <div class="m-t">{{$t('ProductName')}}：{{orderInfo.productTitle}}</div>
        <!--行程路線-->
        <div class="m-t">{{$t('Itinerary route')}}：{{orderInfo.lineName}} - {{orderInfo.lineTitle}}</div>
        <!--旅行團出發日-->
        <div class="m-t">{{$t('TourDepartureDay')}}：{{orderInfo.priceDate}}</div>
        <!--人數-->
        <div class="m-t">{{$t('Number of people')}}：{{passengerInfo.length}}</div>
        <!--房間數目-->
        <div v-if="isDay === 0" class="m-t">{{$t('NumberOfRooms')}}：{{orderInfo.roomsNumber}}</div>
      </div>
      <div class="fl-group" v-if="orderNotice.message">
        <div class="title">{{$t('Messages From Agency')}}</div>
        <!--訂單編號-->
        <el-input type="textarea"
                  placeholder="Messages From Agency"
                  v-model="orderNotice.message"
                  size="7"
                  disabled=true>
        </el-input>
      </div>
      <div class="fl-group" v-if="orderNotice&&orderNotice.isInformation==='Y'">
        <!--團前簡介會資訊-->
        <div class="title">{{$t('Introduction to the group')}}</div>
        <!--日期-->
        <div>{{$t('Date')}}：{{orderNotice.currentDate}}</div>
        <!--時間-->
        <div class="m-t">{{$t('Time')}}：{{orderNotice.currentTime}}</div>
        <!--地點-->
        <div class="m-t">{{$t('Location')}}：{{orderNotice.location}}</div>
        <!--備註-->
        <div class="m-t">{{$t('Remarks')}}：{{orderNotice.memo}}</div>
      </div>
    </div>
  </Modal>
</template>
<script>
  export default {
    name: 'GroupNotice',
    props: {orderInfo: Object, passengerInfo: Array, contactInformation: Object, orderNotice: Object, isDay: Number},
    data() {
      return {
        isShow: false
      };
    },
    computed: {
      appellationName() {
        if (this.contactInformation) {
          let appellation = '';
          const isCalled = this.contactInformation.isCalled;
          if (isCalled) {
            appellation = isCalled.substring(0, 1) + isCalled.substring(1).toLocaleLowerCase() + '.';
          }
          return `${appellation} ${this.contactInformation.englishSurname}`;
        }
        return '';
      }
    },
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      }
    }
  };
</script>
<style scoped lang="less">
  .group-notice{
    padding:0 30px 40px;text-align:left;
    .actions{margin-top:40px;}
  }
  .fl-group{
    .title{
      position:relative;margin:30px 0 24px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
      span{color:#140d08;font-size:14px;}
    }
    .m-t{margin-top:8px;}
  }
</style>
