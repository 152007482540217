<template>
  <div class="main-rt">
    <!--<div class="cover">
      <img class="img" :src="orderInfo.photoUrl"/>
    </div>-->
    <div class="infos">
      <!--<p class="p1">{{$t('Product Overview')}}</p>-->
      <h2 class="h2">{{orderInfo.productTitle}}</h2>
      <div class="passenger">
        <span>{{$t('DepartureDate')}}:{{orderInfo.priceDate}}</span><br/>
        <span>{{$t('Route')}}:{{orderInfo.lineName}} - {{orderInfo.lineTitle}}</span><br/>
        <span>{{$t(orderInfo.priceType!=='1'?'Adult':'Passenger')}}:{{orderInfo.adultNumber}}</span>
        <template v-if="orderInfo.priceType!=='1'">
          <span>{{$t('Elderly')}}:{{orderInfo.elderlyNumber}}</span>
          <span>{{$t('Child')}}:{{orderInfo.childNumber+orderInfo.notChildNumber}}</span>
          <span>{{$t('Baby')}}:{{orderInfo.babyNumber}}</span>
        </template>
        <span v-if="isDay===0">{{$t('NumberOfRooms')}}:{{orderInfo.roomsNumber}}</span>
      </div>
      <table class="table">
        <tr>
          <td class="b">{{$t('Project')}}</td>
          <td class="b" width="75">{{$t('Price')}}</td>
          <td class="b" width="35">{{$t('Quantity')}}</td>
          <td class="b" width="75">{{$t('Amount')}}</td>
        </tr>
        <tr>
          <td v-if="isTicket">{{$t('Adult')}}</td>
          <td v-else>{{$t(orderInfo.priceType!=='1'?'AdultPrice':'Passenger')}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.adultPrice/100}}</td>
          <td>{{orderInfo.adultNumber}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.adultPrice*orderInfo.adultNumber/100}}</td>
        </tr>
        <tr v-if="orderInfo.elderlyNumber">
          <td v-if="isTicket">{{$t('Elderly')}}</td>
          <td v-else>{{$t('Elderly')}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.elderlyPrice/100}}</td>
          <td>{{orderInfo.elderlyNumber}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.elderlyNumber*orderInfo.elderlyPrice/100}}</td>
        </tr>
        <tr v-if="orderInfo.childNumber">
          <td v-if="isTicket">{{$t('Child')}}</td>
          <td v-else>{{$t('ChildPrice')}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.childPrice/100}}</td>
          <td>{{orderInfo.childNumber}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.childNumber*orderInfo.childPrice/100}}</td>
        </tr>
        <tr v-if="orderInfo.notChildNumber">
          <td v-if="isTicket">{{$t('Child')}}</td>
          <td v-else>{{$t('NotChildPrice')}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.notChildPrice/100}}</td>
          <td>{{orderInfo.notChildNumber}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.notChildNumber*orderInfo.notChildPrice/100}}</td>
        </tr>
        <tr v-if="orderInfo.babyNumber">
          <td>{{$t('BabyPrice')}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.babyPrice/100}}</td>
          <td>{{orderInfo.babyNumber}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.babyNumber*orderInfo.babyPrice/100}}</td>
        </tr>
        <tr v-if="orderInfo.singleBedNumber">
          <td>{{$t('SingleBedDifference')}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.singleBedPrice/100}}</td>
          <td>{{orderInfo.singleBedNumber}}</td>
          <td>{{orderInfo.billingType}} {{orderInfo.singleBedNumber*orderInfo.singleBedPrice/100}}</td>
        </tr>
        <template v-if="orderInfo.isInsurance==='Y'">
          <tr>
            <td>{{$t('Insurance')}}{{orderInfo.priceType!=='1'?`-${$t('Adult')}`:''}}</td>
            <td>{{orderInfo.billingType}} {{orderInfo.insuranceAdultPrice/100}}</td>
            <td>{{orderInfo.adultNumber}}</td>
            <td>{{orderInfo.billingType}} {{orderInfo.insuranceAdultTotalPrice}}</td>
          </tr>
          <tr v-if="orderInfo.priceType!=='1'&&(orderInfo.childNumber+orderInfo.notChildNumber+orderInfo.babyNumber)">
            <td>{{$t('Insurance')}}-{{$t('Child')}}/{{$t('Baby')}}</td>
            <td>{{orderInfo.billingType}} {{orderInfo.insuranceChildPrice/100}}</td>
            <td>{{orderInfo.childNumber+orderInfo.notChildNumber+orderInfo.babyNumber}}</td>
            <td>{{orderInfo.billingType}} {{orderInfo.insuranceChildTotalPrice}}</td>
          </tr>
        </template>
      </table>
      <div class="cell total-fee">
        <div class="label b">{{$t('Total amount')}}：</div>
        <div class="value">
          <span class="unit">{{orderInfo.billingType}}</span> {{orderInfo.totalMoney||orderInfo.totalPrice}}
        </div>
      </div>
      <a class="btn-submit" href="javascript:;" @click="onNext" v-if="showBtnSubmit">{{$t('CheckOrder')}}</a>
      <slot></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ConfirmRightSidebar',
    props: {
      showBtnSubmit: {
        type: Boolean,
        default: true
      },
      isDay: Number,
      isTicket: String,
      orderInfo: Object,
      scrollTop: Number,
      isFixed: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {};
    },
    created() {
    },
    methods: {
      onNext() {
        this.$emit('submit');
      }
    }
  };
</script>
<style scoped lang="less">
  .main-rt{
    overflow:hidden;background-color:#fff;
    margin: 0 25px 10px 25px;
    border-style:solid;
    border-width:1px;
    border-color: #f4f4f4;
    .cover{height:200px;margin:5px 5px 20px;overflow:hidden;border-radius:4px;}
    .infos{top:60px;padding:0 20px 30px;background-color:#fff;z-index: 2}
    .p1{color:#999;}
    .h2{margin-top:10px;font-size:24px;}
    .passenger{
      margin-top:16px;
      span{display:inline-block;margin-right:10px;color:#ff6f61;}
    }
    .cell{
      margin-bottom:10px;overflow:hidden;
      &:last-child{margin-bottom:0;}
      .label{
        float:left;
        span{color:#b8b8b8;font-size:12px;}
      }
      .value{float:right;}
    }
    .total-fee{
      margin-top:20px;line-height:36px;
      .value{color:#ff6f61;font-size:24px;}
      .unit{font-size:16px;}
    }
    .btn-submit{display:block;margin-top:30px;line-height:66px;text-align:center;color:#fff;font-size:24px;background-color:#fe6f60;background-image:linear-gradient(45deg, #fad7a1 0%, #fe6f60 100%);border-radius:4px;}
    .table{
      width:100%;margin-top:20px;font-size:12px;
      td{padding:5px 0;}
    }
  }

</style>
