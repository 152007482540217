<template>
  <modal :title="$t('Cancel order')" width="800px" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <tabs class="content-wrap" v-model="activeName">
      <!--取消整張訂單-->
      <tab-pane :label="$t('CancelEntireOrder')" name="ALL">
        <div v-if="step===0">
          <!--您是否確定取消此訂單？訂單號：-->
          <i18n path="Are you sure you want to cancel this order" tag="div" class="h3">
            <span>{{$t('Order number')}}：{{orderNo}}</span>
          </i18n>
          <div class="select-wrap">
            <!--取消原因-->
            <span class="label">{{$t('CancellationReason')}}：</span>
            <el-select class="select" size="small" :placeholder="$t('PleaseChoose')" v-model="cancelReason">
              <el-option v-for="item in cancelReasonOptions"
                         :key="item.code"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-input class="m-t"
                      type="textarea"
                      :placeholder="$t('Other reasons')"
                      v-model="otherReasons"
                      maxlength="1000"
                      v-if="cancelReason==='cancelOhter'">
            </el-input>
          </div>
          <div class="table-wrap">
            <!--取消條款及退款安排-->
            <div class="tit">{{$t('Terms of cancellation and refund arrangements')}}：</div>
            <cancellation-policy-table :policy="policies" :show-travel-agency="false"/>
          </div>
          <div class="actions">
            <!--取消-->
            <button class="button m-r" @click="hide">{{$t('Cancel')}}</button>
            <!--了解取消條款，下一步-->
            <button class="button button-primary" :disabled="confirmButtonLoading" @click="cancelOrderPre">
              {{$t('Understand cancellation terms, next step')}}
            </button>
          </div>
        </div>
        <div v-else-if="step===1">
          <div class="table-wrap">
            <!--取消條款及退款安排-->
            <div class="tit">{{$t('Terms of cancellation and refund arrangements')}}：</div>
            <refund-table :details="chargeDetails"/>
          </div>
          <checkbox class="m-t" v-model="agreement1">
            <div class="checkbox-label">本人同意以上退款明細，並確定取消此訂單？</div>
          </checkbox>
          <checkbox class="m-t" v-model="agreement2">
            <div class="checkbox-label">
              本人同意「一遊」平台的<a class="is-link">退款政策</a>
            </div>
          </checkbox>
          <div class="actions">
            <!--不取消-->
            <button class="button m-r" @click="hide">{{$t('DoNotCancel')}}</button>
            <!--確認取消-->
            <el-button class="button button-primary"
                    :loading="submitButtonLoading"
                    :disabled="!agreement1||!agreement2"
                    @click="onSubmit">
              {{$t('ConfirmCancel')}}
            </el-button>
          </div>
        </div>
      </tab-pane>
      <!--取消個別旅客-->
      <tab-pane :label="$t('CancelIndividualPassengers')" name="Individual">
        <div class="individual-wrap">
          <!--您若需要取消個別旅客，請聯繫客服處理。-->
          <div class="h2">{{$t('p1')}}</div>
          <!--聯繫客服-->
          <a href="https://wa.me/85226160676"><button class="button button-primary">{{$t('ContactCustomerService')}}</button></a>
          <!--或-->
          <div class="p1">{{$t('p2')}}</div>
          <!--致電客服熱線：(852) 2616 0676-->
          <div class="p2">{{$t('p3')}}</div>
        </div>
      </tab-pane>
    </tabs>
  </modal>
</template>
<script>
  import Modal from '../../../components/Modal';
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import Checkbox from '../../../components/Checkbox';
  import CancellationPolicyTable from '../../../components/CancellationPolicyTable';
  import RefundTable from '../../../components/RefundTable';
  import {getDictList} from '../../../api/common';
  import {getRefundPolicy, cancelOrderPre, cancelOrderSure} from '../../../api/order';

  export default {
    name: 'CancelOrder',
    i18n: {
      messages: {
        'zh-cn': {
          p1: '您若需要取消个别旅客，请联系客服处理。',
          p2: '或',
          p3: '致电客服热线：(852) 2616 0676'
        },
        'zh-hk': {
          p1: '您若需要取消個別旅客，請聯繫客服處理。',
          p2: '或',
          p3: '致電客服熱線：(852) 2616 0676'
        },
        'en': {
          p1: '您若需要取消個別旅客，請聯繫客服處理。',
          p2: '或',
          p3: '致電客服熱線：(852) 2616 0676'
        }
      }
    },
    data() {
      return {
        isShow: false,
        activeName: 'ALL',
        step: 0,
        orderId: '',
        orderNo: '',
        cancelReasonOptions: [],
        cancelReason: '',
        otherReasons: '',
        policies: [],
        agreement1: false,
        agreement2: false,
        confirmButtonLoading: false,
        submitButtonLoading: false,
        chargeDetails: [],
        callback: null
      };
    },
    components: {RefundTable, CancellationPolicyTable, Checkbox, Modal, Tabs, TabPane},
    created() {
      this.getDicts();
    },
    methods: {
      show(orderId, orderNo, callback) {
        this.step = 0;
        this.orderId = orderId;
        this.orderNo = orderNo;
        this.callback = callback;
        this.cancelReason = '';
        this.otherReasons = '';
        this.getRefundPolicy(orderId);
        this.isShow = true;
      },
      hide() {
        this.callback = null;
        this.isShow = false;
      },
      // 获取数据字典
      getDicts() {
        // 取消原因
        getDictList('cancelReason').then(res => {
          this.cancelReasonOptions = res.value || [];
        });
      },
      // 获取退费政策
      getRefundPolicy(orderId) {
        this.policies = [];
        getRefundPolicy(orderId).then(res => {
          this.policies = res.value || [];
        });
      },
      // 退款预处理
      cancelOrderPre() {
        if (!this.cancelReason) {
          this.$message.warning(this.$t('Please choose', [this.$t('CancellationReason')]));
          return false;
        }
        this.confirmButtonLoading = true;
        cancelOrderPre(this.orderId).then(res => {
          this.chargeDetails = res.value || [];
          this.confirmButtonLoading = false;
          this.step = 1;
        }).catch(() => {
          this.confirmButtonLoading = false;
        });
      },
      // 确定取消订单
      onSubmit() {
        const data = {orderId: this.orderId, cancelReason: this.cancelReason, otherReasons: this.otherReasons};
        this.submitButtonLoading = true;
        cancelOrderSure(data).then(res => {
          this.submitButtonLoading = false;
          if (typeof this.callback === 'function') {
            this.callback();
          }
          this.$emit('update');
          this.hide();
          this.$message.success('提交成功!');
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .content-wrap{
    padding:0 30px 30px;text-align:left;
    .h3{margin-top:20px;color:#000;font-size:18px;}
    .select-wrap{
      margin-top:16px;
      > .label{display:inline-block;line-height:32px;vertical-align:top;}
    }
    .select{width:220px;vertical-align:top;}
    .actions{margin-top:30px;text-align:center;}
    .m-t{margin-top:16px;}
    .total-refund{text-align:right;font-size:20px;font-weight:bold;}
    .is-link{text-decoration:underline;color:#ff6f61;}
    /deep/ .tab-nav{margin-top:16px;text-align:left;display: grid;}
  }
  .table-wrap{
    margin-top:30px;
    .tit{padding-bottom:5px;font-size:18px;}
    /deep/ .table-policy{margin-top:0;}
  }
  .individual-wrap{
    padding:80px 0 50px;text-align:center;
    .h2{margin-bottom:20px;color:#000;font-size:18px;}
    .p1{padding:20px 0;}
    .p2{font-size:18px;}
  }
</style>
