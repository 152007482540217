<template>
  <div class="layout main cl" v-loading="loading" id="printTest">
    <el-row :gutter=10>
      <el-col :span="18" :xs="24" >
        <div class="main-lf">
          <div class="fl-group">
            <div class="hd hd-back">
              <!--訂單狀態-->
              {{$t('Order status')}}：{{$t('UOrderStatus')[orderInfo.orderStatus]}}
            </div>
            <router-link :to="{name:'OrderList'}">
              <button class="button small m-r back" onclick="" >
                {{$t('Back to Order')}}
              </button>
            </router-link>
            <div class="bd">
              <!--訂單編號-->
              <p class="p2">{{$t('Order number')}}：{{orderInfo.orderNo}}</p>
              <!--預定時間-->
              <p class="p2">{{$t('Scheduled time')}}：{{orderInfo.addDate |formatDate('yyyy-MM-dd hh:mm')}}</p>
              <div class="actions">
                <!--查看成團通知書-->
                <button class="button small button-primary m-r"
                        @click="()=>$refs.groupNotice.show()"
                        v-if="orderNotice">
                  {{$t('ViewGroupNotice')}}
                </button>
                <!--查看收據-->
                <button class="button small m-r" @click="openInNewTab(orderInfo.receiptUrl)" v-if="orderInfo.receiptUrl != null">
                  {{$t('ViewReceipt')}}
                </button>
                <!--取消订单(未支付的)-->
                <div v-if="orderInfo.orderSource !== 'mission'">
                  <el-button class="button small m-r"
                             :loading="cancelButtonLoading"
                             @click="cancelOrderPaid(orderInfo.orderId,'CANCEL')"
                             v-if="orderInfo.orderStatus==='NOT_PAYMENT'">
                    {{$t('Cancel order')}}
                  </el-button>
                  <!--取消订单(已支付的)-->
                  <el-button class="button small m-r"
                             :loading="cancelButtonLoading"
                             @click="cancelOrder(orderInfo.orderId,orderInfo.orderNo)"
                             v-else-if="['NOT_SIGN_UP_SUCCESS','NOT_GROUP','YES_GROUP'].includes(orderInfo.orderStatus)">
                    {{$t('Cancel order')}}
                  </el-button>
                </div>
                <!--付款-->
                <button class="button small button-primary"
                        @click="orderContinuePay(orderInfo.orderId)"
                        v-if="orderInfo.orderStatus==='NOT_PAYMENT'">
                  {{$t('Payment')}}
                </button>
              </div>
            </div>
          </div>
          <!--門票-->
          <div class="fl-group" v-if="this.isTicket==='Y' && this.orderInfo.paymentStatus === 'PAYMENT'">
            <div class="hd">{{$t('Ticket')}}</div>
            <div class="bd">
              <p class="p2">{{$t('Please present the QRCode to the ticket office')}}</p>
              <qrcode :value="scannerCode" :options="{ width: 250 }"></qrcode>
            </div>
          </div>
          <!--退款詳情-->
          <!-- 僅當退款單存在及訂單狀態為,USER_CONFIRM_REFUND USER_CHECK_REFUND  BUSINESS_CHECK_REFUND 時才顯示-->
          <div class="fl-group" v-if="orderCancel &&
        (orderInfo.orderStatus == 'USER_CONFIRM_REFUND' || orderInfo.orderStatus == 'USER_CHECK_REFUND' || orderInfo.orderStatus == 'BUSINESS_CHECK_REFUND')">
            <div class="hd cl">
              <!--取消及退款表/退款詳情-->
              <a class="btn-cancel" href="javascript:;" @click="showRefundDetails" >
                {{$t(orderCancel.userObject==='business'?'CancellationAndRefundForm':'Refund details')}}
              </a>
              {{$t('Refund details')}}
            </div>
            <div class="bd">
              <!--退款狀態-->
              <p class="p2">{{$t('RefundStatus')}}：{{orderCancel.cancelStatus|dictName('refundStatus')}}</p>
              <template v-if="orderCancel.cancelStatus==='OVER_REFUND'">
                <!--退款時間-->
                <p class="p2">{{$t('RefundTime')}}：{{orderCancel.addTime|formatDate}}</p>
                <!--退款金額-->
                <p class="p2">{{$t('RefundAmount')}}：{{orderCancel.deductionAmount}}</p>
                <!--退款編號-->
                <p class="p2">{{$t('RefundNumber')}}：{{orderCancel.cancelId}}</p>
              </template>
              <div class="actions" v-if="orderInfo.orderStatus==='BUSINESS_CHECK_REFUND'">
                <!--拒绝-->
                <button class="button small m-r" @click="updateOrderStatus('REFUSE_REFUND')">
                  {{$t('Refuse')}}
                </button>
                <!--同意-->
                <el-button
                  :loading="cancelButtonLoading"
                  class="button button-primary small"
                  @click="updateOrderStatus('CANCEL')">
                  {{$t('Agree')}}
                </el-button>
              </div>
            </div>
          </div>
          <!--訂單內容-->
          <div class="fl-group">
            <div class="hd">{{$t('Order content')}}</div>
            <div class="bd">
              <!--旅行社名稱-->
              <p class="p2">{{$t('Travel agency name')}}：{{orderInfo.businessName}}</p>
              <!--行程路線-->
              <p class="p2">{{$t('Itinerary route')}}：{{orderInfo.lineName}} - {{orderInfo.lineTitle}}</p>
              <!--旅行團出發日-->
              <p class="p2">{{$t('TourDepartureDay')}}：{{orderInfo.priceDate}}</p>
              <!--人數-->
              <p class="p2">{{$t('Number of people')}}：
                {{orderInfo.adultNumber+orderInfo.childNumber+orderInfo.notChildNumber+orderInfo.babyNumber}}
              </p>
              <!--房間數目-->
              <p class="p2" v-if="isDay===0">{{$t('NumberOfRooms')}}：{{orderInfo.roomsNumber}}</p>
            </div>
          </div>
          <!--旅客信息-->
          <div class="fl-group" v-if="isTicket === 'N'">
            <div class="hd">{{$t('Traveler information')}}</div>
            <div class="bd" v-for="item of passengerInfo" :key="item.id">
              <p class="p2">
                <span class="b">{{$t('Name')}}({{$t('English')}})：{{item.englishSurname}}/{{item.englishName}}</span>
              </p>
              <p class="p2">{{$t('Name')}}({{$t('Chinese')}})：{{item.chineseSurname}}{{item.chineseName}}</p>
              <p class="p2">{{$t('Gender')}}：{{item.genderType|dictName('gender')}}</p>
              <p class="p2">{{$t('TravellerType')}}：{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</p>
              <p class="p2">{{$t('Certificate Type')}}：{{item.paperworkType|dictName(certificateTypeOptions)}}</p>
              <p class="p2" v-if="item.paperworkType==='qt'">{{$t('Other Certificate')}}：{{item.otherPaperwork}}</p>
              <p class="p2">{{$t('Certificate of Issue')}}：{{item.issuingCountry|dictName(countryOptions)}}</p>
              <p class="p2">{{$t('Certificate Number')}}：{{item.idNumber}}</p>
              <p class="p2">{{$t('Certificate Validity Period')}}：{{item.effectiveDate}}</p>
              <p class="p2">{{$t('DateOfBirth')}}：{{item.birthday}}</p>
              <p class="p2">{{$t('Mobile Number')}}：({{item.phoneType}}) {{item.userPhone}}</p>
              <p class="p2" v-if="orderInfo.roomsNumber%1 === 0.5&&item.isFight==='Y'&&isDay===0">
                {{$t('FightPassenger')}}：{{$t('SharedRoom')}}
              </p>
            </div>
          </div>
          <!--聯絡人資料-->
          <div class="fl-group">
            <div class="hd">{{$t('ContactInformation')}}</div>
            <div class="bd">
              <p class="p2">{{$t('Appellation')}}：{{contactInformation.isCalled|dictName(appellationOptions)}}</p>
              <p class="p2">{{$t('Name')}}({{$t('English')}})：{{contactInformation.englishSurname}}/{{contactInformation.englishName}}</p>
              <p class="p2">{{$t('Name')}}({{$t('Chinese')}})：{{contactInformation.chineseSurname}}{{contactInformation.chineseName}}</p>
              <p class="p2">{{$t('Mobile Number')}}：({{contactInformation.phoneType}}) {{contactInformation.userPhone}}</p>
              <p class="p2">{{$t('Email')}}：{{contactInformation.userEmail}}</p>
            </div>
          </div>
          <!--紧急聯絡人資料-->
          <div class="fl-group" v-if="emergencyContact.id">
            <div class="hd">{{$t('Emergency Contact')[0]}}</div>
            <div class="bd">
              <p class="p2">
                {{$t('Name')}}({{$t('English')}})：{{emergencyContact.englishSurname}}/{{emergencyContact.englishName}}
              </p>
              <p class="p2">
                {{$t('Name')}}({{$t('Chinese')}})：{{emergencyContact.chineseSurname}}{{emergencyContact.chineseName}}
              </p>
              <p class="p2">{{$t('Mobile Number')}}：({{emergencyContact.phoneType}}) {{emergencyContact.userPhone}}</p>
            </div>
          </div>

          <!--收據-->
          <div class="fl-group" >
            <div class="hd">{{$t('Receipt')}}</div>
            <div class="bd">
              <!--收據抬頭-->
              <div class="form-item">
                <div class="label">{{$t('Receipt up')}}：{{memo.gainGround}}</div>
              </div>
            </div>
          </div>

          <!--其他需求-->
          <div class="fl-group" >
            <div class="hd">{{$t('More demand')}}</div>
            <div class="bd">
              <!--其他需求-->
              <div class="form-item">
                <div class="label">{{$t('Other demands')}}：{{memo.otherDemands}}</div>
                <div ></div>
              </div>
            </div>
          </div>
          <br>
          <!--聯絡商戶修改資料-->
          <div class="fl-group" >
            <div class="bd">
              <i>{{$t('Modify Info')}}</i>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6" :xs="24"  class="right-sidebar">
        <confirm-right-sidebar class="order-info" :order-info="orderInfo" :show-btn-submit="false" :isDay="isDay">
          <div class="actions">
            <!--        <button class="btn-save-as"  onClick="">存為PDF</button>-->
            <button class="btn-print" v-print="'#printTest'">列印</button>
          </div>
        </confirm-right-sidebar>
      </el-col>
    </el-row>
    <refund-details-modal :user-object="orderCancel?orderCancel.userObject:''"
                          :country-options="countryOptions"
                          :certificate-type-options="certificateTypeOptions"
                          :appellation-options="appellationOptions"
                          :isDay="isDay"
                          ref="refundDetails">
      <div style="margin-top:40px;text-align:center;" v-if="orderInfo.orderStatus==='BUSINESS_CHECK_REFUND'">
        <!--拒绝-->
        <button class="button m-r" @click="updateOrderStatus('REFUSE_REFUND')">
          {{$t('Refuse')}}
        </button>
        <!--同意-->
        <button class="button button-primary" @click="updateOrderStatus('CANCEL')">
          {{$t('Agree')}}
        </button>
      </div>
    </refund-details-modal>
    <cancel-order ref="cancelOrder"/>
    <group-notice :order-info="orderInfo"
                  :passenger-info="passengerInfo"
                  :contact-information="contactInformation"
                  :order-notice="orderNotice"
                  :isDay="isDay"
                  ref="groupNotice"/>
  </div>
</template>
<script>
import ConfirmRightSidebar from './components/ConfirmRightSidebar';
import RefundDetailsModal from './components/RefundDetailsModal';
import CancelOrder from './components/CancelOrder';
import GroupNotice from './components/GroupNotice';
import order from '../../mixins/order';
import {getOrderDetail, updateOrderStatus, getOrderPayStatus, getOrderReceipt, getOrderNotice, getProduct} from '../../api/order';
import {getDictList} from '../../api/common';

export default {
  name: 'OrderDetail',
  components: {GroupNotice, CancelOrder, RefundDetailsModal, ConfirmRightSidebar},
  data() {
    return {
      loading: true,
      orderId: '',
      orderInfo: {},
      passengerInfo: [],
      contactInformation: {},
      emergencyContact: {},
      countryOptions: [],
      certificateTypeOptions: [],
      appellationOptions: [],
      orderCancel: null,
      orderReceiptUrl: '',
      orderNotice: null,
      productId: '',
      isDay: 0,
      isTicket: '',
      memo: {},
      cancelButtonLoading: false,
      scannerCode: ''
    };
  },
  mixins: [order],
  created() {

  },
  mounted() {
    document.querySelector('html').classList.add('bg');
    this.orderId = this.$route.params.id;
    this.getDicts();
    this.getOrderDetail();
  },
  destroyed() {
    document.querySelector('html').classList.remove('bg');
  },
  methods: {
    // 获取字典
    getDicts() {
      // 国家/证件签发地
      getDictList('country').then(res => {
        this.countryOptions = res.value || [];
      });

      // 证件类型
      getDictList('certificateType').then(res => {
        this.certificateTypeOptions = res.value || [];
      });

      // 获取称谓选项
      getDictList('appellative').then(res => {
        this.appellationOptions = res.value || [];
      });
    },
    // 获取订单详情
    getOrderDetail() {
      this.loading = true;
      getOrderDetail(this.orderId).then(res => {
        let order = res.value.order || {};
        const contacts = res.value.contacts || [];
        // 获取需求
        this.memo = res.value.memo || {};
        // 获取产品id
        this.productId = order.productId;
        this.orderInfo = this.formatOrder(order);
        this.orderInfo.addDate = this.orderInfo.addDate + (8 * 60 * 60 * 1000);
        this.passengerInfo = res.value.travelers || [];
        this.contactInformation = this.createContactInformation(contacts);
        this.emergencyContact = this.createContactInformation(contacts, 'urgent');
        this.orderCancel = res.value.orderCancel;
        this.scannerCode = order.scannerCode;
        this.getOrderReceipt();
        this.getOrderNotice();
        this.getProduct();
        this.loading = false;
      });
    },
    getProduct() {
      getProduct(this.productId).then(res => {
        this.isDay = res.value.isDay;
        this.isTicket = res.value.isTicket;
      });
    },
    // 显示退款详情弹窗
    showRefundDetails() {
      this.$refs.refundDetails.show();
    },
    // 修改订单状态
    updateOrderStatus(orderStatus) {
      this.loading = true;
      this.cancelButtonLoading = true;
      updateOrderStatus({orderId: this.orderInfo.orderId, orderStatus}).then(res => {
        this.cancelButtonLoading = false;
        this.orderInfo.orderStatus = orderStatus;
        this.orderCancel.cancelStatus = orderStatus;
        this.$refs.refundDetails.hide();
        this.loading = false;
      });
    },
    // 取消订单(未支付的)
    cancelOrderPaid(orderId, orderStatus) {
      this.cancelButtonLoading = true;
      this.$msgbox({
        title: this.$t('Prompt'),
        message: this.$t('confirm.cancelOrder'),
        center: true,
        showClose: false,
        showCancelButton: true,
        lockScroll: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            updateOrderStatus({orderId, orderStatus}).then(res => {
              this.orderInfo.orderStatus = orderStatus;
              instance.confirmButtonLoading = false;
              done();
            }).catch(() => {
              instance.confirmButtonLoading = false;
            });
          } else {
            done();
          }
        }
      }).then(action => {
        this.cancelButtonLoading = false;
      }).catch(() => {
        this.cancelButtonLoading = false;
      });
    },
    // 取消订单
    cancelOrder(orderId, orderNo) {
      this.$refs.cancelOrder.show(orderId, orderNo, () => {
        this.orderInfo.orderStatus = 'USER_CHECK_REFUND';
      });
    },
    // 继续付款
    orderContinuePay(orderId) {
      const loading = this.$loading({text: 'Loading'});

      // 检查当前订单是否已支付
      getOrderPayStatus(orderId).then(res => {
        loading.close();
        if (res.value === 'success') {
          this.$message.success('當前訂單已付款');
        } else {
          const routeUrl = this.$router.resolve({
            name: 'ConfirmOrder',
            query: {orderId: orderId, active: 'PAYMENT', type: 'CONTINUE'}
          });
          window.open(routeUrl.href, '_blank');
        }
      }).catch(() => {
        loading.close();
      });
    },
    // 获取订单收据
    getOrderReceipt() {
      if (['NOT_GROUP', 'YES_GROUP', 'FINISH'].includes(this.orderInfo.orderStatus)) {
        getOrderReceipt(this.orderId).then(res => {
          if (res.value) {
            this.orderReceiptUrl = res.value.fileUrl;
          }
        });
      }
    },
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    // 获取订单成团通知
    getOrderNotice() {
      // 已成团和已完成的才能查看
      if (['YES_GROUP', 'FINISH'].includes(this.orderInfo.orderStatus)) {
        getOrderNotice(this.orderId).then(res => {
          if (res.value) {
            this.orderNotice = res.value.notice;
          }
        });
      }
    }
  }
};
</script>
<style scoped lang="less">
.main{margin-top:30px;}
.order-info{
  .actions{margin-top:30px;overflow:hidden;}
  .btn-save-as,
  .btn-print{
    text-align:center;font-size:24px;border-radius:4px;
    &:hover{opacity:0.8;}
  }
  .btn-save-as{float:left;width:128px;line-height:64px;color:#ff6f61;border:1px solid #ff6f61;}
  .btn-print{width:100px;line-height:40px;color:#fff;background-color:#fe6f60;background-image:linear-gradient(45deg, #fad7a1 0%, #fe6f60 100%);display: block;margin: auto;font-size: 20px;border: none}
}
.main-lf{padding:0 30px 30px;overflow:hidden;background-color:#fff;}
.fl-group{
  .hd{
    position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
    &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
  }
  .bd{
    padding:20px;background-color:#f4f4f4;border-radius:4px;
    &:not(:first-child){margin-top:8px;}
  }
  .actions{margin-top:20px;text-align:right;}
  .p2{
    line-height:32px;color:#321908;font-size:16px;
    .b{font-weight:bold;}
  }
}
.btn-cancel{float:right;margin-left:8px;padding:0 8px;line-height:28px;color:#1890ff;font-size:14px;border:1px solid #1890ff;border-radius:4px;}
@media print{
  body, html, #wrapper {
    margin-top:0%;
    display:block;
    height:100%;
  }
  .header{
    display: none;
  }
}
.back{
  padding: 10px 5px;
  display: inline-block;
  float: right;
  margin: 30px 0 20px;
}
.hd-back{
  display: inline-block;
}
.right-sidebar{
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .right-sidebar {
    margin-top: 10px;
  }
}
</style>
